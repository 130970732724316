import { NavProvider } from "../../components/Layout/Nav/NavProvider";
import React, { CSSProperties, FC } from "react";
import { getCurrentLanguage } from "../../i18n";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import { t } from "i18next";
import "./index.scss";
import { ContactForm } from "../../components";
import { TextList, TextSlider } from "../../components/TextSlider";
import SectionHeader from '../../components/SectionHeader';
import ArrowMoreContent from '../../components/ArrowMoreContent';
import { YouplanetSections } from '../../constants/general';

const ContactPage: FC = () => {
  const currentLanguage = getCurrentLanguage();
  const slug = typeof window !== 'undefined' ? window.location.pathname : '';
  const textList: TextList[] = [
    { text: t("contact.listen") },
    { text: t("contact.planning")},
    { text: t("contact.imagine") },
  ];

  const description = (text: string) => {
    return (
      <div
        className={`contact__description has-header-animation`}
        style={ titleDelayStyle(0.75)}
      >
        <ArrowMoreContent
          color="#fff"
          className="contact__arrow"
          containerHeight="1.8rem"
          containerWidth="1.8rem"
          sideWidth="0.9rem"
          width=".2rem"
          direction="right"
          clickHandler={null}
        />
        <span>{text}</span>
      </div>
    );
  };
  
  const contactHeader = () => {
    return (
      <div className='contact__infobox'>
        <div className={`contact__content`}>
          <div className="contact__text-section has-header-animation" style={titleDelayStyle(0)}>
            <span className="contact__title">{t("contact.weAre")}</span>
          </div>
          <div className="contact__text-section has-header-animation" style={titleDelayStyle(0.25)}>
            <span className="contact__title contact__title--no-space">
              {t("contact.moreOf")}
            </span>
          </div>
          <div className="contact__text-section contact__text-section--big has-header-animation" style={titleDelayStyle(0.5)}>
            <TextSlider className={"contact__slide-text"} list={textList} />
          </div>
        </div>
      </div>
    );
  };

  const titleDelayStyle = (delay: number) => { 
    return { animationDelay: `${delay}s` } as CSSProperties;
  }

  function onSendEmailSuccessfully() {
    location.href = `/${currentLanguage}/${YouplanetSections.ThankYou}`;
  }

  return (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeText={t("common.contactWelcome")}
        alternativeSlug={'contacto'}
      >
        <SEO
          title={t("contact.pageTitle")}
          description={t("contact.pageDescription")}
          lang={currentLanguage}
          slug={slug}
        />
        <SectionHeader />
        <section className="contact">
          <div className="contact__row">
            <div className="contact__column">
              {contactHeader()}
              {description(t("contact.description"))}
            </div>
            <div className={`contact__column contact__column--start`}>
              <ContactForm onSuccess={onSendEmailSuccessfully} />
            </div>
          </div>
        </section>
      </Layout>
    </NavProvider>
  );
};

export default ContactPage;
